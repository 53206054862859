import React from 'react';
import { Box, Divider, Paper } from '@mui/material';
import { Prism as SyntaxHightlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import classNames from 'classnames';
import { CopyTooltip } from 'src/components/CopyTooltip/CopyTooltip';
import MaskText from 'src/components/MaskText/MaskText';
import { EntityHeader } from 'src/components/EntityHeader/EntityHeader';
import LabelValueDisplay from 'src/components/LabelValueDisplay';
import { Typography } from 'src/components/Typography';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  UserPermission,
  VectorDBStatus,
  VectorDBUser,
} from '@bitnimbus/api-v4';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ExternalLinkIcon from 'src/assets/icons/external-link.svg';

const useStyles = makeStyles((theme: Theme) => ({
  maskText: {
    color: theme.textColors.headlineStatic,
  },
  brokerServerContainer: {
    maxHeight: '4rem',
    overflowY: 'auto',
    margin: `${theme.spacing(1)} 0`,
    borderRadius: theme.spacing(1),
    backgroundColor: '#BFC3CA80',
    padding: '0.5rem',
  },
  brokerServerLabel: {
    textWrap: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emptyBootstrapServer: {
    margin: `${theme.spacing(2)} 0`,
  },
}));

interface VectorDBConnectionDetailsProps {
  user: VectorDBUser | null;
  connection_string: string;
  id: string;
  acl_ips?: string[];
  label: string;
  status: VectorDBStatus;
}

const VectorDBConnectionDetails = (props: VectorDBConnectionDetailsProps) => {
  const classes = useStyles();

  const { user, connection_string } = props;

  return (
    <Paper style={{ margin: 12, padding: 8 }}>
      <Box>
        <EntityHeader title="Connection Info" isSummaryView={true} />
      </Box>
      <Divider style={{ margin: 0 }} />
      <Grid container margin="1.5rem 1rem">
        <Grid xs={6}>
          <Box marginBottom="8px">
            <LabelValueDisplay
              label="Username"
              component={
                <Box display="flex">
                  <code>
                    <CopyTooltip text={user?.username || ''} copyableText />
                  </code>
                  <Box component="div" marginLeft="1rem">
                    <CopyTooltip text={user?.username || ''} />
                  </Box>
                </Box>
              }
              inline={true}
            />
          </Box>
          <Box>
            <LabelValueDisplay
              label="Password"
              component={
                <Box component="span" display="flex" alignItems="baseline">
                  <code>
                    <MaskText
                      text={user?.password || ''}
                      className={classes.maskText}
                    />
                  </code>
                  <Box component="span" marginLeft="1rem">
                    <CopyTooltip text={user?.password || ''} />
                  </Box>
                </Box>
              }
              inline={true}
            />
          </Box>
        </Grid>
        {/* <Grid sm={0.1}>
          <Divider orientation="vertical" textAlign="center" />
        </Grid> */}
        <Grid xs={6}>
          <Box>
            <Box
              className={classNames({
                [classes.brokerServerLabel]: connection_string !== '',
              })}
            >
              <Typography variant="h3">Endpoint</Typography>
              {connection_string && connection_string.length > 0 && (
                <a
                  href={`https://${connection_string}/docs`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon />
                </a>
              )}
            </Box>
            <Box
              component="div"
              display="flex"
              alignItems="center"
              className={classNames({
                [classes.brokerServerContainer]: connection_string !== '',
                [classes.emptyBootstrapServer]: connection_string === '',
              })}
            >
              <code style={{ marginRight: '4px' }}>
                {!connection_string || connection_string.length === 0
                  ? 'Will be available shortly'
                  : `${connection_string}`}
              </code>
              <CopyTooltip
                text={
                  connection_string?.length === 0
                    ? 'Database Unavailable'
                    : connection_string
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VectorDBConnectionDetails;
