export const vectordbConnect = {
  python: `import chromadb
from chromadb.config import Settings

client = chromadb.HttpClient(
    host="<Provide your Vectordb Endpoint>",
    port=443,
    ssl=True,
    settings=Settings(
        chroma_client_auth_provider="chromadb.auth.basic_authn.BasicAuthClientProvider",
        chroma_client_auth_credentials="<Vectordb username>:<vectordb user password>",
    ),
)


try:
    client.heartbeat()
    collection = client.create_collection(name="langflow", get_or_create=True)
    print(collection.name)
    print(f"collection count at collection.count()")
except Exception as e:
    print(e)
`,
};
