import React, { useState } from 'react';
import { Box } from 'src/components/Box';
import { CodeSnippet as _CodeSnippet, Select } from '@bitnimbus/ui-components';
import { Container } from '../styled';
import { Snippet } from '@bitnimbus/api-v4';
import { capitalize } from 'lodash';

export interface CodeSnippetProps {
  sampleCode: Snippet;
}

const CodeSnippet = ({ sampleCode }: CodeSnippetProps) => {
  const languages = Object.keys(sampleCode).map((language: string) => {
    return {
      label: capitalize(language),
      value: language,
    };
  });
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].value);

  return (
    <Box>
      <Container>
        <Box position="absolute" right="2rem" marginTop="4px" zIndex={10}>
          <Select
            value={selectedLanguage}
            options={languages}
            onChange={(value) => setSelectedLanguage(value)}
          />
        </Box>
        <_CodeSnippet
          code={sampleCode[selectedLanguage]}
          language={selectedLanguage}
        />
      </Container>
    </Box>
  );
};

export default CodeSnippet;
