import {
  APIError,
  Filter,
  Kafka,
  Params,
  Response,
  Stats,
  getAllKafka,
  getKafkaById,
  getPlans,
  deleteKafka,
  getKafkaStatsByRange,
  DeleteKafkaRequest,
  updateKafka,
  CreateKafkaRequest,
  createKafka,
  PlansResponse,
  Resource,
  KafkaResponse,
} from '@bitnimbus/api-v4';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Dayjs } from 'dayjs';
import { DateTime } from 'luxon';

import { queryPresets } from './base';
import { parseAPIDate } from 'src/utilities/date';
import { DeepPartial } from 'redux';

const QUERY_KEY = 'kafka';
export const STATS_NOT_READY_API_MESSAGE =
  'Stats are unavailable at this time.';
export const STATS_NOT_READY_MESSAGE = 'Stats are not available yet';

export const useAllKafkaQuery = (
  params: Params = {},
  filter: Filter = {},
  enabled: boolean = true,
  refetchInterval = 20000
) =>
  useQuery<Resource[] | null, APIError[]>(
    [QUERY_KEY, 'all'],
    async () => {
      const result = await getAllKafka(params, filter);
      return result.data || null;
    },
    {
      ...queryPresets.longLived,
      enabled,
      keepPreviousData: true,
      refetchInterval,
    }
  );

export const useKafkaQuery = (
  kafkaId: string,
  enabled: boolean = true,
  refetchInterval = 20000
) =>
  useQuery<KafkaResponse | null, APIError[]>(
    [QUERY_KEY, kafkaId],
    async () => {
      const result = await getKafkaById(kafkaId);
      return result.data || null;
    },
    {
      ...queryPresets.longLived,
      enabled,
      keepPreviousData: true,
      refetchInterval,
    }
  );

export const useCreateKafkaMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<Partial<Kafka>, APIError[], CreateKafkaRequest>(
    async (data) => {
      const result = await createKafka(data);
      return result.data || {};
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY, 'all']);
        queryClient.invalidateQueries(['account', 'usage']);
      },
    }
  );
};

export const useDeleteKafkaMutation = (kafkaID: string) => {
  const queryClient = useQueryClient();
  return useMutation<{}, APIError[], DeleteKafkaRequest>(
    (data) => deleteKafka(kafkaID, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY, 'all']);
        queryClient.invalidateQueries([QUERY_KEY]);
        queryClient.invalidateQueries(['account', 'usage']);
      },
    }
  );
};

export const useUpdateKafkaMutation = (kafkaID: string) => {
  const queryClient = useQueryClient();
  return useMutation<Kafka | null, APIError[], DeepPartial<Kafka>>(
    async (data) => {
      const result = await updateKafka(kafkaID, data);
      return result.data || null;
    },
    {
      onSuccess: (response: Kafka) => {
        const clusterData = queryClient.getQueryData<Response<Kafka>>([
          QUERY_KEY,
          kafkaID,
        ]);
        if (clusterData) {
          queryClient.setQueryData([QUERY_KEY, kafkaID], {
            ...clusterData,
            label: response?.label,
            acl_ips: response?.acl_ips,
            status: response?.status,
          });
          queryClient.invalidateQueries([QUERY_KEY, 'all']);
        }
      },
    }
  );
};

const getIsTooEarlyForStats = (kafkaCreatedAt?: string) => {
  if (!kafkaCreatedAt) {
    return false;
  }

  return parseAPIDate(kafkaCreatedAt) > DateTime.local().minus({ minutes: 7 });
};

export const useKafkaStatsByDateTime = (
  id: string,
  startTime: Date,
  endTime: Date,
  linodeCreated?: string
) => {
  const callback = async () => {
    const result = await getKafkaStatsByRange(
      id,
      startTime.getTime() / 1000,
      endTime.getTime() / 1000
    );
    return result.data || [];
  };
  return useQuery<Stats[], APIError[]>(
    [QUERY_KEY, 'kafka', id, 'stats'],
    getIsTooEarlyForStats(linodeCreated)
      ? () => Promise.reject([{ reason: STATS_NOT_READY_MESSAGE }])
      : callback,
    { enabled: true, retry: false }
  );
};

export const usePlansQUery = () => {
  const plansResponse = useQuery<Response<PlansResponse>, APIError[]>(
    [QUERY_KEY, 'plans'],
    getPlans,
    {
      ...queryPresets.oneTimeFetch,
      ...queryPresets.noRetry,
    }
  );
  return {
    ...plansResponse,
    data: plansResponse.data?.data,
  };
};
