import { styled } from '@mui/material';
export const Container = styled('div', {
  label: 'ManagementContainer',
})(({ theme }) => ({
  width: '100%',
  '& > *': {
    maxHeight: '560px',
    overflowY: 'auto',
    ['&::-webkit-scrollbar']: {
      width: '8px',
    },

    /* Track */
    ['&::-webkit-scrollbar-track']: {
      boxShadow: 'inset 0 0 2px grey',
      borderRadius: '10px',
    },

    /* Handle */
    ['&::-webkit-scrollbar-thumb']: {
      background: '#818589AA',
      borderRadius: '10px',
    },
  },
}));
