import React, { useState } from 'react';
import { Select } from '@bitnimbus/ui-components';
import { Box } from 'src/components/Box';
import Paper from 'src/components/core/Paper';
import { Container } from '../styled';
import YoutubeViewer, { YoutubeViewerProps } from '../YoutubeViewer';
import CodeSnippet, { CodeSnippetProps } from '../CodeView';

export enum GuidePanelType {
  YOUTUBE,
  CODE_VIEW,
}

interface GuidePanelProps {
  title: string;
  type: GuidePanelType;
  content: YoutubeViewerProps | CodeSnippetProps;
}

interface ComponentProps {
  contents: Record<string, GuidePanelProps>;
}

const GuidePanel = (props: ComponentProps) => {
  const [selectedContent, setSelectedContent] = useState(
    Object.keys(props.contents)[0]
  );

  const options = Object.keys(props.contents).map((content: string) => {
    return {
      label: props.contents[content].title,
      value: content,
    };
  });

  const getComponent = () => {
    switch (props.contents[selectedContent].type) {
      case GuidePanelType.YOUTUBE:
        return (
          <Container>
            <YoutubeViewer
              {...(props.contents[selectedContent]
                .content as YoutubeViewerProps)}
            />
          </Container>
        );
      case GuidePanelType.CODE_VIEW:
        return (
          <Box>
            <CodeSnippet
              {...(props.contents[selectedContent].content as CodeSnippetProps)}
            />
          </Box>
        );
    }
  };

  return (
    <Paper style={{ margin: '0.5rem' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box width="25%">
          <Select
            value={selectedContent}
            options={options}
            onChange={(value) => setSelectedContent(value)}
          />
        </Box>
      </Box>
      <Box>{getComponent()}</Box>
    </Paper>
  );
};

export default GuidePanel;
