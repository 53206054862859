import * as React from 'react';
import { useParams } from 'react-router-dom';
import PendingIcon from 'src/assets/icons/pending.svg';
import Paper from 'src/components/core/Paper';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Typography } from 'src/components/Typography';
import { ErrorState } from 'src/components/ErrorState/ErrorState';
import Grid from '@mui/material/Unstable_Grid2';
import {
  STATS_NOT_READY_API_MESSAGE,
  STATS_NOT_READY_MESSAGE,
  useKafkaStatsByDateTime,
} from 'src/queries/kafka';
import { setUpCharts } from 'src/utilities/charts';
import { getAPIErrorOrDefault } from 'src/utilities/errorUtils';
import { Divider, Box } from '@mui/material';
import { EntityHeader } from 'src/components/EntityHeader/EntityHeader';
import RefreshButton from 'src/components/RefreshButton/RefreshButton';
import {
  Card,
  Dataset,
  LineGraph,
  NoData,
  DatePickerWithPresets,
  AxisLabel,
} from '@bitnimbus/ui-components';
import { APIError, Metric, Stats } from '@bitnimbus/api-v4';
import { Container } from '../styled';

setUpCharts();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1),
    },
    title: {
      paddingLeft: 0,
      paddingTop: 0,
    },
    divider: {
      margin: 0,
    },
    chartSelect: {
      width: '100%',
    },
    timeRangeSelector: {
      width: '30%',
      [theme.breakpoints.down(600)]: {
        width: '100%',
      },
    },
    graphControls: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(),
      flexWrap: 'wrap',
      width: '100%',
    },
    graphGrids: {
      display: 'grid',
      gridTemplateColumns: '49% 49%',
      gridGap: '1rem',
      gridRowGap: '1.5rem',
      width: '100%',
    },
    grid: {
      backgroundColor: theme.bg.offWhite,
      border: `solid 1px ${theme.borderColors.divider}`,
      borderRadius: theme.spacing(1),
      marginRight: 0,
      marginBottom: theme.spacing(2),
      margin: 1,
      width: '49%',
      padding: theme.spacing(2),
      '&.MuiGrid-item': {
        padding: theme.spacing(2),
      },
      '& h2': {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(900)]: {
        width: '100%',
      },
    },
    labelRangeSelect: {
      fontSize: '1rem',
      paddingRight: theme.spacing(2),
    },
    emptyText: {
      textAlign: 'center',
      marginTop: theme.spacing(),
    },
    dateTimePicker: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '60%',
      [theme.breakpoints.down(600)]: {
        width: '100%',
      },
    },
  })
);

interface Props {
  createdAt: string;
  resourceId: string;
  isLoading?: boolean;
  error?: APIError[] | null;
  stats?: Stats[];
  updateTimeRange: (timerange: Record<string, Date>) => void;
}

const ResourceMetrics: React.FC<Props> = (props) => {
  const { isLoading, error, stats } = props;

  const [timeRange, setTimeRange] = React.useState({
    from: new Date(Date.now() - 300 * 1000),
    to: new Date(),
  });
  const [refreshTime, setRefreshTime] = React.useState(false);
  const classes = useStyles();
  const statsErrorString = error
    ? getAPIErrorOrDefault(error, 'An error occurred while getting stats.')[0]
        .reason
    : undefined;

  const areStatsNotReady =
    statsErrorString &&
    [STATS_NOT_READY_API_MESSAGE, STATS_NOT_READY_MESSAGE].includes(
      statsErrorString
    );

  React.useEffect(() => {
    props.updateTimeRange(timeRange);
    setRefreshTime(false);
  }, [timeRange]);

  const chartHeight = 360;

  const handleTimeRange = (range: any) => {
    setTimeRange({
      from: range.from,
      to: range.to,
    });
  };

  const handleRefresh = () => {
    setRefreshTime(true);
  };

  if (!props.resourceId) {
    return null;
  }

  const statsNotReadyComponent = (
    <Paper style={{ margin: '12px', width: '100%' }}>
      <ErrorState
        CustomIcon={PendingIcon}
        CustomIconStyles={{ width: 64, height: 64 }}
        errorText={
          <>
            <div>
              <Typography variant="h2" className={classes.emptyText}>
                {STATS_NOT_READY_MESSAGE}
              </Typography>
            </div>
            <div>
              <Typography variant="body1" className={classes.emptyText}>
                {/* Broker and Consumer stats will be available shortly */}
                It will be available once the service has become active
              </Typography>
            </div>
          </>
        }
      />
    </Paper>
  );

  const errorComponent = (
    <Paper style={{ width: '100%' }}>
      <ErrorState
        CustomIconStyles={{ width: 64, height: 64 }}
        errorText={statsErrorString || ''}
      />
    </Paper>
  );

  const getDataset = (metrics: Metric[]) => {
    const datasets: Dataset[] = [];
    let labels: (string | number)[] = [];
    metrics.forEach((metric) => {
      const dataset = {
        label:
          metric.grouped_by && metric.grouped_by.length > 0
            ? metric.grouped_by?.join(' > ')
            : 'value',
        data: metric.values ? metric.values.map((d) => d[1]) : [],
      };
      datasets.push(dataset);
    });
    if (metrics[0].values?.length > 0) {
      labels = metrics[0].values.map((d) => d[0]) || [];
    }
    return {
      labels,
      datasets,
    };
  };

  const getComponent = () => {
    if (!stats || stats?.length === 0) {
      return statsNotReadyComponent;
    }
    if (statsErrorString && !areStatsNotReady) {
      return errorComponent;
    }
    return (
      <Box className={classes.graphGrids}>
        {stats?.map((metric) => {
          if (metric.data.length === 0) {
            return (
              <div style={{ minHeight: chartHeight }} key={metric.title}>
                <NoData title={metric.title} isRefreshing={isLoading} />
              </div>
            );
          }
          const chartData = getDataset(metric.data);
          return (
            <Box key={metric.title}>
              <Card
                title={metric.title}
                isRefreshing={isLoading}
                content={
                  <LineGraph
                    datasets={chartData.datasets}
                    labels={chartData.labels}
                    chartHeight={chartHeight}
                    axisLabel={metric.axis_labels as AxisLabel}
                  />
                }
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Paper style={{ margin: '12px', padding: 10 }}>
      <EntityHeader title="Analytics" isSummaryView={true}>
        <RefreshButton loading={isLoading} onClick={handleRefresh} />
      </EntityHeader>
      <Divider className={classes.divider} />
      <Grid container className={`${classes.root} m0`}>
        <Box
          display="flex"
          width="100%"
          justifyContent="end"
          alignItems="center"
          marginBottom={2}
        >
          <Typography style={{ marginRight: '1rem' }}>
            Select Time Range
          </Typography>
          <Box width="30%">
            <DatePickerWithPresets
              onChange={handleTimeRange}
              refreshTime={refreshTime}
            />
          </Box>
        </Box>
        <Container>{getComponent()}</Container>
      </Grid>
    </Paper>
  );
};

export default ResourceMetrics;
