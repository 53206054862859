import { useParams } from 'react-router';
import React, { ReactNode } from 'react';
import { Notice } from 'src/components/Notice/Notice';
import SuspenseLoader from 'src/components/SuspenseLoader';
import {
  useDeleteVectorDBMutation,
  useUpdateVectorDBMutation,
  useVectorDBQuery,
} from 'src/queries/vectordb';
import ResourceSummary from 'src/features/Resource/ResourceSummary';
import { Resource, UserPermission, VectorDBStatus } from '@bitnimbus/api-v4';
import { Spec as ResourceSpec } from 'src/features/Resource/ResourceSummary/ResourceSpec';
import CPUIcon from 'src/assets/icons/cpu.svg';
import RAMIcon from 'src/assets/icons/ram.svg';
import StorageIcon from 'src/assets/icons/storage.svg';
import { useSnackbar } from 'notistack';
import { getAPIErrorOrDefault } from 'src/utilities/errorUtils';
import { useProfile } from 'src/queries/profile';
import VectorDBManagement from '../VectorDBManagement';

interface VectorDBDetailProps {
  label: string;
}

const VectorDBDetail = (props: VectorDBDetailProps) => {
  const { vectordbId } = useParams<{ vectordbId: string }>();
  const {
    data: vectordb,
    isLoading,
    error,
    isRefetching,
    refetch,
    isSuccess,
  } = useVectorDBQuery(vectordbId);
  const resource = (vectordb as unknown) as Resource;
  const { enqueueSnackbar } = useSnackbar();
  const { data: profile } = useProfile();

  const {
    isLoading: updateLoading,
    mutateAsync: updateVectordb,
    error: updateError,
  } = useUpdateVectorDBMutation(vectordbId);

  const {
    isLoading: deleteLoading,
    mutateAsync: deleteVectordb,
    error: deleteError,
    reset: deleteReset,
  } = useDeleteVectorDBMutation(vectordbId);

  const updateLabel = async (label: string) => {
    try {
      await updateVectordb({
        label,
      });
      enqueueSnackbar('Sucessfully updated VectorDB label', {
        variant: 'success',
      });
    } catch (err) {
      const errMsg = getAPIErrorOrDefault(err);
      enqueueSnackbar(errMsg[0].reason || 'Error updating VectorDB label', {
        variant: 'error',
      });
      throw new Error(errMsg[0].reason);
    }
  };

  const handleRefetch = async () => {
    await refetch();
  };

  const handleDelete = async () => {
    await deleteVectordb();
  };

  const canDelete = () => {
    if (profile.permissions !== UserPermission.FULL) {
      return "You don't have permission to delete this resource";
    }
    if (
      ![VectorDBStatus.ACTIVE, VectorDBStatus.FAILED].includes(
        resource.status as VectorDBStatus
      )
    ) {
      return 'You cannot delete the resource if it is not in active state';
    }
    return null;
  };

  const icons: Record<string, ReactNode> = {
    memory: <RAMIcon />,
    vcpus: <CPUIcon />,
    disk: <StorageIcon />,
  };
  const specs: ResourceSpec[] = vectordb?.plan?.specs
    ? vectordb?.plan.specs?.map((spec) => ({
        label: spec.label,
        value: spec.value,
        icon: icons[spec.key],
      }))
    : [];
  if (isLoading) {
    return <SuspenseLoader />;
  }
  if (error) {
    return <Notice error>{error[0].reason}</Notice>;
  }
  return (
    <div>
      <ResourceSummary
        title="VectorDB Summary"
        path={`/VectorDB/${resource?.label}`}
        resource={((vectordb as unknown) as Resource) || null}
        price_hourly={vectordb?.plan.price?.hourly || 0}
        plan={vectordb?.plan.label || ''}
        specs={specs}
        updateLabel={updateLabel}
        isLoading={isLoading}
        isRefetching={isRefetching || updateLoading}
        refetchResource={handleRefetch}
        error={updateError}
        updateCompleted={isSuccess}
        deleteReset={deleteReset}
        deleteError={deleteError}
        deleteLoading={deleteLoading}
        handleDelete={handleDelete}
        deleteEnabled={canDelete() === null}
        deleteDisabledMessage={canDelete() || ''}
      />
      <VectorDBManagement resource={resource} vectordb={vectordb || null} />
    </div>
  );
};

export default VectorDBDetail;
