import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Notice } from 'src/components/Notice/Notice';
import Paper from 'src/components/core/Paper';
import { Tab } from 'src/components/core/ReachTab';
import { TabList } from 'src/components/core/ReachTabList';
import TabPanel from 'src/components/core/ReachTabPanel';
import TabPanels from 'src/components/core/ReachTabPanels';
import Tabs from 'src/components/core/ReachTabs';
import { Typography } from 'src/components/Typography';
import { useTheme } from '@mui/material/styles';
import { CircleProgress } from 'src/components/CircleProgress';
import { Box } from '../Box';
import InputLabel from '../core/InputLabel';

export interface Tab {
  title: string;
  render: (props: any) => JSX.Element | null;
}

interface TabbedPanelProps {
  header: string;
  error?: string | JSX.Element;
  copy?: string;
  rootClass?: string;
  sx?: SxProps;
  innerClass?: string;
  tabs: Tab[];
  [index: string]: any;
  initTab?: number;
  bodyClass?: string;
  noPadding?: boolean;
  handleTabChange?: (index: number) => void;
  value?: number;
  docsLink?: JSX.Element;
  loading?: boolean;
}

const TabbedPanel = React.memo((props: TabbedPanelProps) => {
  const {
    header,
    error,
    copy,
    rootClass,
    sx,
    innerClass,
    tabs,
    handleTabChange,
    docsLink,
    initTab,
    ...rest
  } = props;

  const [tabIndex, setTabIndex] = useState<number | undefined>(initTab);

  const theme = useTheme();

  const tabChangeHandler = (index: number) => {
    setTabIndex(index);
    if (handleTabChange) {
      handleTabChange(index);
    }
  };

  useEffect(() => {
    if (tabIndex !== initTab) {
      setTabIndex(initTab);
    }
  }, [initTab, tabs]);

  return (
    <Paper
      className={rootClass}
      sx={{ flexGrow: 1, ...sx }}
      data-qa-tp={header}
    >
      <div className={innerClass}>
        <Grid container sx={{ display: 'flex' }}>
          {header !== '' && (
            <Grid xs={6}>
              <InputLabel>{props.header}</InputLabel>
            </Grid>
          )}
          {docsLink ? (
            <Grid
              xs={6}
              style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              {docsLink}
            </Grid>
          ) : null}
        </Grid>
        {error && (
          <Box marginTop={2}>
            <Notice error>{error}</Notice>
          </Box>
        )}
        {copy && <StyledTypography data-qa-tp-copy>{copy}</StyledTypography>}
        {props.loading ? (
          <CircleProgress size="3rem" thickness={5} noInner={true} />
        ) : (
          <Tabs
            onChange={tabChangeHandler}
            index={tabIndex}
            sx={{ position: 'relative' }}
          >
            <StyledTabList>
              {tabs.map((tab, idx) => (
                <StyledTab
                  key={`tabs-${tab.title}-${idx}`}
                  data-testid={`tab-${tab.title}__button`}
                >
                  {tab.title}
                </StyledTab>
              ))}
            </StyledTabList>
            <TabPanels>
              {tabs.map((tab, idx) => (
                <TabPanel key={`tabs-panel-${tab.title}-${idx}`}>
                  {tab.render(rest.children)}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </div>
    </Paper>
  );
});

export { TabbedPanel };

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  marginTop: theme.spacing(1),
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  'div &[data-reach-tab-list]': {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    '&button': {
      '&:focus': {
        backgroundColor: theme.bg.tableHeader,
      },
      '&:hover': {
        backgroundColor: `red !important`,
      },
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  '&[data-reach-tab]': {
    '&:focus': {
      backgroundColor: theme.bg.tableHeader,
    },
    '&:hover': {
      backgroundColor: theme.bg.tableHeader,
    },
  },
}));
