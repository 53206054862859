import React from 'react';
import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import Paper from 'src/components/core/Paper';

export interface YoutubeViewerProps {
  src: string;
}

const YoutubeViewer = (props: YoutubeViewerProps) => {
  return (
    <Box>
      <iframe
        width="720"
        height="480"
        style={{ margin: '0.5rem auto', borderRadius: '8px' }}
        src={props.src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default YoutubeViewer;
