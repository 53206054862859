import { Resource, UserPermission, VectorDBStatus } from '@bitnimbus/api-v4';
import React from 'react';
import Paper from 'src/components/core/Paper';
import IpAcl from 'src/features/IpAcl';
import { useProfile } from 'src/queries/profile';
import { useUpdateVectorDBMutation } from 'src/queries/vectordb';

interface VectorDBIPAclProps {
  resource: Resource;
  acl_ips?: string[];
}

const VectorDBIPAcl = ({ resource, acl_ips }: VectorDBIPAclProps) => {
  const {
    mutateAsync: updateVectordb,
    error: updateError,
    isLoading: updateLoading,
  } = useUpdateVectorDBMutation(resource.id);

  const { data: profile } = useProfile();

  const handleUpdate = async (ipAcl: string[]) => {
    await updateVectordb({
      acl_ips: ipAcl,
    });
  };

  const canUpdate = () => {
    return profile.permissions === UserPermission.FULL &&
      resource.status === VectorDBStatus.ACTIVE &&
      acl_ips &&
      acl_ips.length > 0
      ? true
      : false;
  };
  return (
    <Paper>
      <IpAcl
        resourceId={resource.id || ''}
        ipAcl={acl_ips || []}
        label={resource.label || ''}
        canUpdate={canUpdate()}
        isLoading={updateLoading}
        error={updateError}
        handleUpdate={handleUpdate}
        enitiy="VectorDB"
      />
    </Paper>
  );
};

export default VectorDBIPAcl;
