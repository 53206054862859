import React, { useState } from 'react';
import { Tab, TabbedPanel } from 'src/components/TabbedPanel/TabbedPanel';
import Metrics from './Metrics';
import ConnectionDetail from './ConnectionDetail';
import CodeView from 'src/features/Resource/CodeView';
import { Resource, VectorDB, VectorDBStatus } from '@bitnimbus/api-v4';
import VectorDBIPAcl from './IpAcl';
import GuidePanel, { GuidePanelType } from 'src/features/Resource/GuidePanel';
import { vectordbConnect } from 'src/assets/samples/vectordb';

interface VectorDBExploreProps {
  resource: Resource;
  vectordb: VectorDB | null;
}

const VectorDBManagement = ({ resource, vectordb }: VectorDBExploreProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs: Tab[] = [
    {
      title: 'Connect',
      render: () => {
        return (
          <ConnectionDetail
            user={vectordb?.vectordb_users ? vectordb.vectordb_users[0] : null}
            connection_string={vectordb?.url || ''}
            label={resource.label}
            id={resource.id}
            status={resource.status as VectorDBStatus}
          />
        );
      },
    },
    {
      render: () => (
        <Metrics vectordbId={resource.id} createdAt={resource.created_on} />
      ),
      title: 'Analytics',
    },
    {
      render: () => (
        <VectorDBIPAcl resource={resource} acl_ips={vectordb?.acl_ips} />
      ),
      title: 'Networking',
    },
    {
      render: () => (
        <GuidePanel
          contents={{
            vectordbConnect: {
              title: 'Connect to VectorDB',
              type: GuidePanelType.CODE_VIEW,
              content: {
                sampleCode: vectordbConnect,
              },
            },
          }}
        />
      ),
      title: 'Getting Started',
    },
  ];

  return (
    <TabbedPanel
      tabs={tabs}
      sx={{ backgroundColor: 'transparent', width: '100%', padding: 0 }}
      header=""
      initTab={selectedTab}
      handleTabChange={(tab) => setSelectedTab(tab)}
    />
  );
};

export default VectorDBManagement;
